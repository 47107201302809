import React from "react";
import { graphql } from "gatsby";
import BackgroundImage from "../components/background-image";
import { Container } from "styled-bootstrap-grid";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Map from "../components/map";
import Row from "../components/row";
import Col from "../components/col";
import Box from "../components/box";
import ExternalLink from "../components/external-link";

const shortcodes = { Row, Col, Column: Col, Map, Box, ExternalLink };

export default function PageTemplate({ data }) {
  const { mdx } = data; // data.markdownRemark holds your post data
  const { frontmatter, body } = mdx;

  const { backgroundImage, backgroundImageOffset, pageHeader } = frontmatter;

  return (
    <>
      <BackgroundImage
        imageData={backgroundImage}
        offset={backgroundImageOffset}
        title={pageHeader}
      ></BackgroundImage>
      <Container>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </Container>
    </>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        description
        pageHeader
        backgroundImage {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: FULL_WIDTH
              transformOptions: { fit: COVER }
            )
          }
        }
        backgroundImageOffset
      }
    }
  }
`;
