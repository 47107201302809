import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function ExternalLink({
  children,
  ...props
}: {
  children: React.ReactNode;
  href: string;
}) {
  return (
    <a target="_blank" rel="noopener noreferrer" {...props}>
      {children}{" "}
      <sup>
        <FontAwesomeIcon size="sm" icon={faExternalLinkAlt} />
      </sup>
    </a>
  );
}

export default ExternalLink;
