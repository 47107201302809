import styled from "styled-components";
import { media } from "styled-bootstrap-grid";

const Row = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: -${({ theme }) => theme.baseMargin / 2}rem;
  margin-right: -${({ theme }) => theme.baseMargin / 2}rem;
  margin-bottom: ${({ theme }) => theme.baseMargin}rem;

  > * {
    margin-left: ${({ theme }) => theme.baseMargin / 2}rem;
    margin-right: ${({ theme }) => theme.baseMargin / 2}rem;
    margin-bottom: ${({theme}) => theme.baseMargin}rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${media.md`
    flex-direction: row;
  `}
`;

export default Row;
