import styled, { useTheme } from "styled-components";
import React from "react";
import { media } from "styled-bootstrap-grid";
import { Parallax } from "react-parallax";
import { useMediaQuery } from "react-responsive";
import HeroContainer, { HeroH1 } from "./hero-container";

function useIsMobile() {
  const { breakpoints } = useTheme() as any;
  return !useMediaQuery({ query: `(min-width: ${breakpoints.md}px)` });
}

function Title({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <HeroContainer className={className}>
      <HeroH1>{children}</HeroH1>
    </HeroContainer>
  );
}

const MobileTitle = styled(Title)`
  display: block;
  ${media.md`
  display: none;
`}
`;

const DesktopTitle = styled(Title)`
  display: none;
  ${media.md`
  display: block;
`}
`;

const BackgroundImageWrapper = styled.div`
  margin-bottom: ${({ theme }: { theme: any }) => theme.baseMargin / 2}rem;

  img {
    top: 25px;
    height: calc(
      350px + ${({ theme }: { theme: any }) => theme.parallaxStrengthMobile}px
    );
  }

  ${media.md`
  margin-bottom: ${({ theme }: { theme: any }) => theme.baseMargin * 1.5}rem;
  img {
    top: 75px;
    height: calc(95vh + ${({ theme }: { theme: any }) =>
      theme.parallaxStrengthDesktop}px);
  }

`}
`;

const ParallaxInner = styled.div`
  position: relative;
  height: ${({ theme }: any) => theme.heroImageMinHeight};
  min-height: none;

  ${media.md`
  min-height: ${({ theme }: any) => theme.heroImageMinHeight};
 height: ${({ theme }: any) => theme.heroImageHeight};
`}
`;

export default function BackgroundImage({
  imageData,
  title,
  description,
}: {
  imageData: any;
  title: string;
  offset: number;
  description: string;
  children: React.ReactNode;
}) {
  const { parallaxStrengthMobile, parallaxStrengthDesktop } = useTheme() as any;
  const {
    src,
    srcSet,
    sizes,
  } = imageData.childImageSharp.gatsbyImageData.images.fallback;
  const isMobile = useIsMobile();

  return (
    <BackgroundImageWrapper>
      <Parallax
        strength={isMobile ? parallaxStrengthMobile : parallaxStrengthDesktop}
        bgImage={src}
        bgImageSizes={sizes}
        bgImageSrcSet={srcSet}
        bgImageAlt={description}
      >
        <ParallaxInner />
        <DesktopTitle>{title}</DesktopTitle>
      </Parallax>
      <MobileTitle>{title}</MobileTitle>
    </BackgroundImageWrapper>
  );
}
