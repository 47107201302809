import React from "react";
import styled from "styled-components";
import Row from "./row";
import Col from "./col";
import { media } from "styled-bootstrap-grid";

const Box = styled(({ color, ...rest }) => <div {...rest} />)`
  background-color: ${({ color, theme }) => color || theme.boxColor};
  padding: ${({ theme }) => theme.baseMargin / 2}rem;
  margin-bottom: ${({ theme }) => theme.baseMargin}rem;
  border-radius: ${({ theme }) => theme.baseMargin / 4}rem;
  > *:last-child {
    margin-bottom: 0;
  }

  .gatsby-resp-image-wrapper {
    max-width: 200px !important;
    margin-left: 0;
    margin-right: 0;
  }

  ${media.md`
  > ${Row} {
    > ${Col} {
      margin-bottom: 0;
    }
  }
  padding: ${({ theme }) => theme.baseMargin}rem;
`}
`;

export default Box;
