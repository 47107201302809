import React from "react";
import styled from "styled-components";
const StyledIFrame = styled.iframe`
  height: 300px;
  border: 0;
  width: 100%;

  margin-bottom: ${({ theme }) => theme.baseMargin}rem;
`;

export default function Map() {
  return (
    <StyledIFrame
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1102.776717759494!2d-1.5078398847051222!3d55.0321629032453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e6e0385ccc071%3A0xc6d85c1f2829a88a!2sShiremoor%20Adventure%20Playground%20Please%20note%20sundays%20and%20monday%20are%20for%20children%20with%20additional%20needs%20and%20their%20families!5e0!3m2!1sen!2suk!4v1620160908186!5m2!1sen!2suk"
      allowFullScreen={false}
      loading="lazy"
    ></StyledIFrame>
  );
}
