import styled from "styled-components";
import { Container, media } from "styled-bootstrap-grid";

const HeroContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: 0;

  > * {
    margin-left: auto;
    margin-right: auto;
  }

  ${media.md`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  `}
`;

export const HeroH1 = styled.h1`
  font-size: ${({ theme }) => theme.baseFontSizePixels * 2}px;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.titleFont.color};
  background-color: white;
  display: block;
  padding: 0;
  line-height: 1.3;
  border-radius: 0.5rem;
  margin-top: ${({ theme }) => theme.baseMargin / 2}rem;
  margin-bottom: 0;

  ${media.md`
  font-size: ${({ theme }) => theme.baseFontSizePixels * 3}px;
  width: 100%;
  padding: ${({ theme }: { theme: any }) => theme.baseMargin / 2}rem ${({
    theme,
  }) => theme.baseMargin}rem;
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-bottom: 0;

`}
  ${media.lg`
  width: 70%;
`}
`;

export default HeroContainer;
