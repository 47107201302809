import styled from "styled-components";
import { media } from "styled-bootstrap-grid";

const Col = styled.div`
  flex-grow: 1;
  flex-basis: 0;

  margin: ${({ theme }) => theme.baseMargin / 2}rem;
  margin-bottom: ${({ theme }) => theme.baseMargin}rem;
  margin-top: 0;

  ${media.md`
  margin-bottom: 0;
  `}

  > .gatsby-resp-image-wrapper {
    img {
      border-radius: 0.7rem;
    }
  }
  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;
export default Col;
